
import { Component, Vue, Watch } from 'vue-property-decorator'
import { KeepAlive } from '../../../utils/decorators'
import CustomExport from '../customExport/Index.vue'
import SafeSet from './SafeSet.vue'
import ManageDetail from './ManageDetail.vue'
import NoData from '@/components/NoData.vue'

@Component({
  components: { CustomExport, SafeSet, ManageDetail, NoData }
})
@KeepAlive
export default class TaskPlanList extends Vue {
  private searchForm: {
    planName: string;
    projectId: string;
    safeManageId: string;
    patrolMode: string;
    planType: string;
    planStatus: number;
    patrolUnit: string;
  } = {
    planName: '',
    projectId: '',
    safeManageId: '',
    patrolMode: '',
    planType: '',
    planStatus: 0,
    patrolUnit: ''
  }

  private isShowRecord = false
  private showExport = false
  private setShow = false
  private loading = false
  private safeManageList = []
  private patrolModeList = ['线路', '点位']
  private planTypeList = ['日巡查', '周巡查', '月巡查']
  private planStatusList = ['启用', '禁用']
  private patrolUnitList = ['管理抽查', '项目自查']
  private planList = []
  private planId = ''
  page = 1
  size = 10
  total = 0
  get projectList () {
    return this.$store.state.projectList
  }

  @Watch('$store.state.buttonList', { deep: true, immediate: true })
  buttonListChange () {
    this.initSearchForm()
  }

  created () {
    // 1
  }

  initSearchForm () {
    this.patrolUnitList = [] // ['管理抽查', '项目自查']
    if (this.$store.state.buttonList.some((item: { butCode: string }) => item.butCode === 'PLA_SEL_04')) {
      this.patrolUnitList.unshift('项目自查')
      this.searchForm.patrolUnit = '2'
    }
    if (this.$store.state.buttonList.some((item: { butCode: string }) => item.butCode === 'PLA_SEL_03')) {
      this.patrolUnitList.unshift('管理抽查')
      this.searchForm.patrolUnit = '1'
    }
    this.loadSafeManageList()
    this.patrolUnitList.length > 0 && this.loadData()
  }

  loadSafeManageList () {
    this.$axios.get(this.$apis.patrol.selectSafeManageList).then(res => {
      this.safeManageList = res || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.searchForm.patrolUnit === '1' ? this.$apis.patrol.selectPlaneByPage : this.$apis.patrol.selectPlanePage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.planList = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onAdd () {
    this.$router.push({ name: 'patrolTaskAdd' })
  }

  onDetail (id: string) {
    this.$router.push({
      name: 'patrolPointDetail',
      params: {
        id
      }
    })
  }

  onOperate (row: any) {
    if (row.planStatus === 1 && row.planeStopTime && this.$dayjs().valueOf() > this.$dayjs(row.planeStopTime).valueOf()) {
      this.$confirm('自动禁用日期需大于当前日期或为空才可开启任务, 是否继续?', '提示', {
        confirmButtonText: '去编辑',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.isShowRecord = true
      })
      return
    }
    this.$confirm(`确认${row.planStatus === 0 ? '禁用' : '启用'}该任务【${row.planName}】, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.patrol.updatePlanStatus, {
        planId: row.planId
      }).then(() => {
        this.loadData()
      })
    })
  }

  onEdit (planId: string) {
    this.isShowRecord = true
    this.planId = planId
  }

  onRecord (planId: string) {
    this.$router.push({ name: 'patrolTaskPlanDetail', params: { id: planId } })
  }

  onDelete (row: { planName: string; planId: string }) {
    this.$confirm(`确认删除该计划【${row.planName}】, 是否继续?`, '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.patrol.deletePlane, {
        planId: row.planId
      }).then(() => {
        this.loadData()
      })
    })
  }
}
