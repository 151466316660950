<template>
  <el-dialog
    title="编辑"
    :visible="isShowRecord"
    width='600px'
    :append-to-body="true"
    :destroy-on-close="true"
    :close-on-click-modal='false'
    @close="onClose">
    <el-form class="global-add-form" ref="form" :model="formData" :rules="rules" label-width="120px" label-position="right">
      <el-form-item label="任务名称" prop="planName">
        <el-input v-model="formData.planName" placeholder="请输入任务名称"></el-input>
      </el-form-item>
      <el-form-item label="任务执行人" prop="executeUserId">
        <el-select placeholder="请选择任务执行人" v-model="formData.executeUserId" filterable clearable>
          <el-option v-for="(item, index) in userList" :label="item.userName" :value="item.userId" :key="index"></el-option>
        </el-select>
      </el-form-item>
      <div class="tips">提示：编辑后不影响已生成的巡查任务执行人</div>
      <el-form-item label="巡查频次" prop="patrolFrequency">
        <el-input-number step-strictly :step="1" v-model="formData.patrolFrequency" controls-position="right" :min="1" :max="31" @change="frequencyChange" placeholder="请输入巡查频次"></el-input-number>&nbsp;次/{{filtPlanType(formData.planType)}}
      </el-form-item>
      <el-form-item label="巡查时段" prop="timeList" :rules="{required: true, type: 'array', message: '请选择巡查时间段', trigger: ['blur', 'change']}">
        <div class="timeList" v-for="(time, index) of formData.timeList" :key="index">
          <el-form-item
            :prop="`timeList.${index}.startTime`"
            :rules="{required: true, message: '请选择开始时间', trigger: ['change']}"
            :key="'start' + index">
            <el-time-select
              v-model="time.startTime"
              value-format="HH:mm"
              format="HH:mm"
              :picker-options="{
                        start: index !== 0 ? formData.timeList[index - 1].endTime || '00:00' : '00:00',
                        end: formData.timeList[index].endTime || '24:00',
                        step: '00:15'
                    }"
              placeholder="开始时间">
            </el-time-select>至
          </el-form-item>
          <el-form-item
            :prop="`timeList.${index}.endTime`"
            :rules="{required: true, message: '请选择结束时间', trigger: ['change']}"
            :key="'end' + index">
            <el-time-select
              v-model="time.endTime"
              value-format="HH:mm"
              format="HH:mm"
              :picker-options="{
                        start: formData.timeList[index].startTime || '00:00',
                        end: index < formData.timeList.length - 1 ? formData.timeList[index + 1].startTime || '24:00' : '24:00',
                        step: '00:15',
                        minTime: formData.timeList[index].startTime || '00:00'
                    }"
              placeholder="结束时间">
            </el-time-select>
          </el-form-item>
        </div>
      </el-form-item>
      <el-form-item label="生效方式" prop="generateType">
        <el-radio :disabled="radioDisabled" v-model="formData.generateType" :label="1" border>次{{filtPlanType(formData.planType)}}生效</el-radio>
        <el-tooltip class="item" effect="dark" :content="`表示次${filtPlanType(formData.planType)}生成的任务信息，不影响本${filtPlanType(formData.planType)}已生成的任务`" placement="top">
          <i class="el-icon-warning"></i>
        </el-tooltip>
        <div class="right-radio">
          <el-radio :disabled="radioDisabled" v-model="formData.generateType" :label="2" border>立即生效</el-radio>
          <el-tooltip class="item" effect="dark" :content="`表示立即生成本${filtPlanType(formData.planType)}任务，本${filtPlanType(formData.planType)}已生成的非已完成任务将被自动删除`" placement="top">
            <i class="el-icon-warning"></i>
          </el-tooltip>
        </div>
      </el-form-item>
      <el-form-item label="自动禁用日期" prop="planeStopTime">
        <el-date-picker v-model="formData.planeStopTime" :picker-options="pickerOptions" type="date" format="yyyy-MM-dd" value-format="yyyy-MM-dd" placeholder="请选择自动禁用日期"></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSave" :disabled="disabled">保存</el-button>
        <el-button type="info" @click="onClose">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  // planType 1日巡查2周巡查3月巡查
  name: 'ExecutionRecord',
  props: {
    isShowRecord: {
      type: Boolean,
      default: false
    },
    planId: String
  },
  data () {
    return {
      disabled: false,
      radioDisabled: false,
      userList: [],
      formData: {
        planId: '',
        executeUserId: '',
        planName: '',
        patrolFrequency: '',
        generateType: 1,
        timeList: [{
          startTime: '',
          endTime: ''
        }],
        planeStopTime: ''
      },
      rules: {
        planName: [
          { required: true, message: '请输入任务名称', trigger: ['blur', 'change'] }
        ],
        executeUserId: [
          { required: true, message: '请选择任务执行人', trigger: ['blur', 'change'] }
        ],
        handlerIds: [
          { required: true, message: '请选择任务处理人', trigger: ['blur', 'change'] }
        ],
        patrolFrequency: [
          { required: true, message: '请输入巡查频次', trigger: ['blur', 'change'] }
        ]
      },
      pickerOptions: {
        disabledDate: date => {
          return this.$dayjs(date).valueOf() < this.$dayjs().valueOf()
        }
      }
    }
  },
  created () {
    // this.init()
  },
  watch: {
    isShowRecord (val) {
      if (val) {
        this.init()
      }
    }
  },
  computed: {
  },
  methods: {
    init () {
      this.loadData().then(() => {
        this.loadUserList()
      })
    },
    loadData () {
      return this.$axios.get(this.$apis.patrol.selectPlaneByPlaneId, {
        planId: this.planId
      }).then(res => {
        if (!res.timeList) {
          res.timeList = [{
            startTime: '00:00',
            endTime: '24:00'
          }]
        }
        this.formData = res || {}
      })
    },
    loadUserList () {
      this.$axios.get(this.$apis.patrol.selectUserList, {
        userType: '1',
        projectId: this.formData.projectId,
        safeManageId: this.formData.safeManageId
      }).then(res => {
        this.userList = res || []
        if (this.userList.length === 1) {
          this.formData.executeUserId = this.userList[0].userId
        }
      })
    },
    // 1日巡查2周巡查3月巡查
    filtPlanType (val) {
      if (val === 1) {
        this.formData.generateType = 1
        this.radioDisabled = true
        return '日'
      } else if (val === 2) {
        this.radioDisabled = false
        return '周'
      } else {
        this.radioDisabled = false
        return '月'
      }
    },
    // 日巡查 巡查频次变更
    frequencyChange () {
      const len = this.formData.timeList && this.formData.timeList.length
      const val = Number(this.formData.patrolFrequency.toFixed(0))
      if (len > val) {
        this.formData.timeList.splice(val, len - val)
      } else {
        if (this.formData.planType === 1) {
          let i = len
          while (i < val) {
            this.formData.timeList.push({
              startTime: '',
              endTime: ''
            })
            i++
          }
        }
      }
    },
    onSave () {
    //   console.log('表单数据', this.formData)
      this.$refs.form.validate().then(() => {
        this.disabled = true
        this.$axios.post(this.$apis.patrol.updatePlane, {
          ...this.formData
        }).then(res => {
        //   console.log('编辑成功')
          this.onClose()
          this.$emit('loadData')
        }).finally(() => {
          this.disabled = false
        })
      })
    },
    onClose () {
      this.$emit('update:isShowRecord', false)
    }
  }
}
</script>

<style scoped lang="scss">
.tips {
    margin-left: 100px;
    margin-bottom: 20px;
    color: #d30c0c;
}
.timeList {
    display: flex;
    align-items: center;
    ::v-deep .el-date-editor {
      width: 180px;
    }
}
.right-radio {
    display: inline-block;
    margin-left: 40px;
}
::v-deep {
    .el-icon-warning {
        margin-left: -20px;
    }
}
</style>
